import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia } from '@material-ui/core';
import logoCalendar from '../assets/add-to-calendar.png';

export default function Button({ children, onClick }) {
  return (
    <CardMedia
      component="img"
      alt={children}
      image={logoCalendar}
      title={children}
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
      ref={el => {
        if (el) {
          el.style.setProperty('max-width', `${35 * (window.zoom ? window.zoom : 1)}px`, 'important');
        }
      }}
    />
  );
}

Button.propTypes = {
  children: PropTypes.objectOf(PropTypes.object()).isRequired,
  onClick: PropTypes.objectOf(PropTypes.object()).isRequired,
};
